.AdminPage {
  padding-bottom: 3vh;
  padding-top: 3vh;
  transform: translateY(15px);
  transition: opacity 1s ease, transform 1s ease;
  animation: fadeIn 1s;
}

.list {
  overflow-y: scroll;
  height: 25vh;
  transform: translateY(15px);
  transition: opacity 1s ease, transform 1s ease;
  animation: fadeIn 1s;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 10px 0 0 10px;
}

.item_list {
  cursor: pointer;
}

.item_list:hover {
  background-color: #807f83;
  color: white;
}

.item_list_active {
  color: white !important;
  background-color: #045cac;
  font-weight: bold;
}

.title {
  width: 100%;
  text-align: -webkit-center;
}

.form_item {
  transform: translateY(15px);
  transition: opacity 1s ease, transform 1s ease;
  animation: fadeIn 1s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
