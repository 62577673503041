.form_item {
  transform: translateY(15px);
  transition: opacity 1s ease, transform 1s ease;
  animation: fadeIn 1s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
