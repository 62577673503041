body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.pt-2 {
  padding-top: 2vh;
}

.pb-8 {
  padding-bottom: 8vh;
}

.content {
  padding-left: 10%;
  padding-right: 10%;
  min-height: 79vh;
}

.img-background {
  height: 10vh;
  width: 10vh;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
  border-radius: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.15);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #045cac;
}

.alert {
  position: fixed;
  top: 2vh;
  right: 0;
  width: 30%;
  z-index: 100;
  transition: opacity 1s ease, transform 1s ease;
  animation: fadeIn 1s;
}
@media screen and (max-width: 480px) {
  .alert {
    width: 80%;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
